import React, { ChangeEvent } from 'react';
import { CtaSection } from '../../Types';
import ImageInputField from '../InputComponents/ImageInputField';
import { t } from 'i18next';

interface CtaSectionFormProps {
  initialContent: CtaSection['content'];
  onSave: (updatedContent: CtaSection['content']) => void;
}

const CtaSectionForm: React.FC<CtaSectionFormProps> = ({ initialContent, onSave }) => {
  const [content, setContent] = React.useState<CtaSection['content']>(initialContent);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleSave = () => {
    onSave(content);
  };

  return (
    <div>
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background:`)}</small>
        <input
          type="color"
          name="background_color"
          value={content.background_color || '#ffffff'} // Ensure default color
          onChange={handleChange}
        />
        <small>{t(`Text:`)}</small>
        <input
          type="color"
          name="text_color"
          value={content.text_color || '#ffffff'} // Ensure default color
          onChange={handleChange}
        />
        <small>{t(`Button:`)}</small>
        <input
          type="color"
          name="button_color"
          value={content.button_color || '#ffffff'} // Ensure default color
          onChange={handleChange}
        />
        <small>{t(`Button Text:`)}</small>
        <input
          type="color"
          name="button_text_color"
          value={content.button_text_color || '#ffffff'} // Ensure default color
          onChange={handleChange}
        />
      </div>
      <label>{t(`Background Image URL:`)}</label>
      <ImageInputField
        name="background_image"
        value={content.background_image || ''}
        onChange={handleChange} />
      <label>{t(`Text:`)}</label>
      <input
        type="text"
        name="text"
        value={content.text}
        onChange={handleChange}
      />
      <label>{t(`Button Text:`)}</label>
      <input
        type="text"
        name="button_text"
        value={content.button_text || ''} // Ensure default empty string
        onChange={handleChange}
      />
      <label>{t(`Button URL:`)}</label>
      <input
        type="text"
        name="button_url"
        value={content.button_url || ''} // Ensure default empty string
        onChange={handleChange}
      />

      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default CtaSectionForm;