import React, { useEffect, useRef, useState } from 'react';
import { Section, ComponentType, HeroSection, FeaturesSection, TestimonialSection, CtaSection, AccordionSection, CardsSection, LogoSection, HorizontalHeroSection, GridFeaturesSection, SpaceSection, SocialLinksSection, TextSection } from '../Types';
import HeroSectionForm from './Components/HeroSectionForm';
import FeaturesSectionForm from './Components/FeaturesSectionForm';
import TestimonialSectionForm from './Components/TestimonialSectionForm';
import CtaSectionForm from './Components/CtaSectionForm';
import Preview from './Preview';
import { componentImages } from './componentImages';
import './LandingPageBuilder.css'
import CardsSectionForm from './Components/CardsSectionForm';
import AccordionSectionForm from './Components/AccordionSectionForm';
import LogoSectionForm from './Components/LogoSectionForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faExpand, faTrash, faUpLong } from '@fortawesome/free-solid-svg-icons';
import HorizontalHeroSectionForm from './Components/HorizontalHeroSection';
import GridFeaturesSectionForm from './Components/GridFeaturesSectionForm';
import SpaceSectionForm from './Components/Space';
import SocialLinksForm from './Components/SocialLinksForm';
import TextSectionForm from './Components/TextSectionForm';
import { useConfirmationDialog } from '../../ConfirmationDialog/ConfirmationDialogProvider';
import { landingPageDefaults } from './landingPageDefaults';
import TemplateDropdown from './LandingPageTemplates';
import { t } from 'i18next';

// Function to get default content based on section type
const getDefaultContent = (type: ComponentType) => {
  switch (type) {
    case 'hero':
      return {
        main_heading: 'Welcome to Our Platform', // Dummy main heading
        subheading: 'Empowering you to achieve more with our innovative solutions.', // Dummy subheading
        background_image: '',
        background_color: landingPageDefaults.background,
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        cta_button: {
          text: 'Get Started', // Dummy call-to-action text
          url: '#',
        },
      } as HeroSection['content'];
    case 'horizontal-hero':
      return {
        main_heading: 'Discover Our Unique Features', // Dummy main heading
        subheading: 'Learn how we stand out in the market.', // Dummy subheading
        image: landingPageDefaults.image,
        description: 'Our platform offers a range of unique features designed to help you maximize your potential. From seamless integration to user-friendly interfaces, we have everything you need to succeed.', // Dummy description
        background_color: landingPageDefaults.background,
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        imageOrientation: 'right',
        cta_button: {
          text: 'Explore Features', // Dummy call-to-action text
          url: '#',
        },
      } as HorizontalHeroSection['content'];
    case 'features':
      return {
        title: 'Why Choose Our Platform',
        background_color: landingPageDefaults.background,
        background_image: '',
        text_color: landingPageDefaults.text,
        items_color: landingPageDefaults.secondBackground,
        button_color: landingPageDefaults.button,
        features: [
          {
            title: 'Feature One',
            description: 'This feature helps you streamline your workflow and increase productivity.',
            icon_url: landingPageDefaults.icon,
          },
          {
            title: 'Feature Two',
            description: 'Leverage this feature to get insightful analytics and make data-driven decisions.',
            icon_url: landingPageDefaults.icon,
          },
        ],
      } as FeaturesSection['content'];
    case 'grid-features':
      return {
        title: 'Our Core Features',
        background_color: landingPageDefaults.background,
        background_image: '',
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        items_color: landingPageDefaults.secondBackground,
        features: [
          {
            title: 'Responsive Design',
            description: 'Our platform is designed to look great on any device, ensuring a seamless experience for all users.',
            icon_url: landingPageDefaults.icon,
          },
          {
            title: 'Advanced Security',
            description: 'We prioritize security with state-of-the-art encryption and data protection measures.',
            icon_url: landingPageDefaults.icon,
          },
        ],
      } as GridFeaturesSection['content'];

    case 'testimonial':
      return {
        title: 'What Our Customers Say',
        background_color: landingPageDefaults.background,
        background_image: '',
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        items_color: landingPageDefaults.secondBackground,
        testimonials: [
          {
            quote: 'This platform has completely transformed the way we do business. Highly recommended!',
            name: 'John Doe',
            image_url: landingPageDefaults.profile,
          },
          {
            quote: 'An exceptional experience with top-notch features and outstanding support.',
            name: 'Jane Smith',
            image_url: landingPageDefaults.profile,
          },
        ],
      } as TestimonialSection['content'];
    case 'cta':
      return {
        background_color: landingPageDefaults.background,
        background_image: '',
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        button_text_color: landingPageDefaults.text,
        text: 'Ready to take the next step? Join us and start your journey today!',
        button_text: 'Get Started Now',
        button_url: '#',
      } as CtaSection['content'];
    case 'accordion':
      return {
        title: 'Frequently Asked Questions',
        background_color: landingPageDefaults.background,
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        panels: [
          {
            title: 'How does the platform work?', // Dummy panel title
            content: 'Our platform offers a variety of tools and features to help you streamline your workflow. From easy setup to advanced analytics, we provide everything you need to succeed.', // Dummy panel content
          },
          {
            title: 'Can I customize my experience?', // Dummy panel title
            content: 'Yes, you can customize your experience with a range of options. Adjust settings, personalize your dashboard, and select features that best fit your needs.', // Dummy panel content
          },
        ],
      } as AccordionSection['content'];
    case 'cards':
      return {
        background_color: landingPageDefaults.background,
        text_color: landingPageDefaults.text,
        button_color: landingPageDefaults.button,
        items_color: landingPageDefaults.secondBackground,
        title: 'Our Services',
        cards: [
          {
            image_url: landingPageDefaults.icon,
            title: 'Service One', // Dummy card title
            description: 'Discover how Service One can help you achieve your goals with our tailored solutions.', // Dummy card description
            link_name: 'Learn More', // Dummy link name
            link_url: '#',
          },
          {
            image_url: landingPageDefaults.icon,
            title: 'Service Two', // Dummy card title
            description: 'Explore the features of Service Two and see how it can transform your workflow.', // Dummy card description
            link_name: 'Explore Now', // Dummy link name
            link_url: '#',
          },
        ],
      } as CardsSection['content'];
    case 'logo':
      return {
        background_color: landingPageDefaults.background,
        image_url: 'https://tzz.at/logo512.png',
        position: 'left',
      } as LogoSection['content'];
    case 'space':
      return {
        background_color: landingPageDefaults.background,
      };
    case 'social-links':
      return {
        background_color: landingPageDefaults.background,
        title: 'Connect with Us',
        position: 'left',
        text_color: landingPageDefaults.text,
        social_links: [
          {
            type: 'facebook',
            link: 'https://facebook.com/yourpage',
          },
          {
            type: 'twitter',
            link: 'https://twitter.com/yourhandle',
          },
        ],
      } as SocialLinksSection['content'];
    case 'text':
      return {
        background_color: landingPageDefaults.background,
        title: 'About Our Mission',
        text_color: landingPageDefaults.text,
        text: 'Our mission is to provide innovative solutions that empower individuals and businesses to achieve their full potential. We are committed to delivering high-quality services and exceptional customer support to help you succeed in every endeavor.', // Dummy text
      } as TextSection['content'];
    default:
      throw new Error('Invalid section type');
  }
};


// Function to create a new section with the correct type
const createSection = (type: ComponentType, content?: any): Section => {
  switch (type) {
    case 'hero':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as HeroSection;
    case 'horizontal-hero':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as HorizontalHeroSection;
    case 'features':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as FeaturesSection;
    case 'grid-features':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as GridFeaturesSection;
    case 'testimonial':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as TestimonialSection;
    case 'cta':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as CtaSection;
    case 'accordion':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as AccordionSection;
    case 'cards':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as CardsSection;
    case 'logo':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as LogoSection;
    case 'space':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as SpaceSection;
    case 'social-links':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as SocialLinksSection;
    case 'text':
      return {
        type,
        content: content ? content : getDefaultContent(type),
      } as TextSection;
    default:
      throw new Error('Invalid section type');
  }
};


interface LandingPageBuilderProps {
  initialContent: Section[];
  onSave: (updatedContent: Section[]) => void;
}

const LandingPageBuilder: React.FC<LandingPageBuilderProps> = ({ initialContent, onSave }) => {
  const [sections, setSections] = useState<Section[]>(initialContent);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number | null>(null);
  const [editingSection, setEditingSection] = useState<Section | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { showConfirmation } = useConfirmationDialog();

  const handleAddSection = (type: ComponentType) => {
    setEditingSection(null);
    setSelectedSectionIndex(null); // Close editing mode
    const newSection = createSection(type);

    setSections((prevSections) => [...prevSections, newSection]);
    setHasUnsavedChanges(true);
  };

  const handleEditSection = (index: number) => {
    if (index === selectedSectionIndex) {
      setSelectedSectionIndex(null); // Close editing mode
      setEditingSection(null);
    } else {
      setSelectedSectionIndex(index);
      setEditingSection(sections[index]);
      setHasUnsavedChanges(true);
    }
  };

  const handleSaveSection = (updatedContent: any['content']) => {
    if (selectedSectionIndex !== null) {
      setSections((prevSections) =>
        prevSections.map((section, index) =>
          index === selectedSectionIndex
            ? { ...section, content: updatedContent }
            : section
        )
      );
      setEditingSection(null); // Close editing mode
      setHasUnsavedChanges(false);
    }
  };

  const handleDeleteSection = async (index: number) => {
    const shouldDelete = await showConfirmation(t('Are you sure for deleting the section?'));
    if (!shouldDelete) {
      return;
    }
    setEditingSection(null);
    setSelectedSectionIndex(null); // Close editing mode
    setSections((prevSections) => prevSections.filter((_, i) => i !== index));
    setHasUnsavedChanges(true);
  };

  const handleTemplateChoose = (sections: Section[]) => {
    setSections(sections);
  }

  const handleMovement = (index: number, direction: 'up' | 'down') => {
    setSections(prevSections => {
      // Create a copy of the current state
      const updatedSections = [...prevSections];

      // Perform the movement
      if (direction === 'up' && index > 0) {
        // Swap with the previous item
        [updatedSections[index - 1], updatedSections[index]] = [updatedSections[index], updatedSections[index - 1]];
      } else if (direction === 'down' && index < updatedSections.length - 1) {
        // Swap with the next item
        console.log(index, index + 1);
        [updatedSections[index], updatedSections[index + 1]] = [updatedSections[index + 1], updatedSections[index]];
      }

      return updatedSections; // Return the new array
    });

    setHasUnsavedChanges(true);
  };

  useEffect(() => {
    // Function to handle the beforeunload event
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        // Some browsers require setting this property to show a confirmation dialog
        event.returnValue = '';
      }
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    onSave(sections);
  }, [sections]);

  const toggleFullScreen = () => {
    const element = componentRef.current;

    if (!isFullscreen && element) {
      // Enter fullscreen
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).webkitRequestFullscreen) { /* Safari */
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) { /* IE11 */
        (element as any).msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      try {
        let hasError = false;
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen().catch(() => { hasError = true });
        } else if ((document as any).webkitExitFullscreen) { /* Safari */
          (document as any).webkitExitFullscreen().catch(() => { hasError = true });
        } else if ((document as any).msExitFullscreen) { /* IE11 */
          (document as any).msExitFullscreen().catch(() => { hasError = true });
        }
      } catch (e) { }
      setIsFullscreen(false);
    }
  };

  return (
    <>
      <div style={{
        backgroundColor: '#F9F9F9', // Set background color
        paddingRight: '5px',
        position: 'relative',
      }}>
        <button className="fullScreenButton" style={{ marginLeft: '5px', marginTop: '10px', backgroundColor: '#1A56B3' }} onClick={toggleFullScreen}>
          <FontAwesomeIcon icon={faExpand} /> {isFullscreen ? 'Exit' : ''} Full Screen Editor
        </button>

        <TemplateDropdown onSelected={handleTemplateChoose}></TemplateDropdown>
      </div>
      <div ref={componentRef} className="landingPageBuilder">

        <div className="builderColumns">
          <div className="available-components">
            <h2>{t(`Components`)}</h2>
            <div className="component-list">
              <div className="component-item" onClick={() => handleAddSection('logo')}>
                <img src={componentImages.logo} alt="Logo" />
                <span>{t(`Logo`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('space')}>
                <img src={componentImages.space} alt="Logo" />
                <span>{t(`Space`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('hero')}>
                <img src={componentImages.hero} alt="Hero" />
                <span>{t(`Hero`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('horizontal-hero')}>
                <img src={componentImages['horizontal-hero']} alt="Hortizontal Hero" />
                <span>{t(`Hortizontal Hero`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('text')}>
                <img src={componentImages.text} alt="Text" />
                <span>{t(`Text`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('social-links')}>
                <img src={componentImages['social-links']} alt="Social Links" />
                <span>{t(`Social Links`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('features')}>
                <img src={componentImages.features} alt="Features" />
                <span>{t(`Features`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('grid-features')}>
                <img src={componentImages['grid-features']} alt="GridFeatures" />
                <span>{t(`Grid Features`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('testimonial')}>
                <img src={componentImages.testimonial} alt="Testimonial" />
                <span>{t(`Testimonial`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('cta')}>
                <img src={componentImages.cta} alt="CTA" />
                <span>{t(`Click To Action`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('accordion')}>
                <img src={componentImages.accordion} alt="Accordion" />
                <span>{t(`Accoirdion`)}</span>
              </div>
              <div className="component-item" onClick={() => handleAddSection('cards')}>
                <img src={componentImages.cards} alt="Cards" />
                <span>{t(`Cards`)}</span>
              </div>
            </div>
          </div>

          {/* Column 2: Added Sections */}
          <div className="addedSections">
            <h2>{t(`Sections`)}</h2>
            {sections && sections.length > 0 && sections.map((section, index) => (
              <div key={index} className="sectionItem">
                <div
                  className="sectionHeader"
                >
                  <h3
                    onClick={() => handleEditSection(index)}>{section.type.charAt(0).toUpperCase() + section.type.slice(1)} Section</h3>
                  <div className="sectionHeaderButtons">
                    {index < sections.length - 1 ?
                      <button className="move-button" onClick={() => {
                        handleMovement(index, 'down');
                      }}><FontAwesomeIcon icon={faDownLong} /></button>
                      : ''
                    }
                    {index > 0 ?
                      <button className="move-button" onClick={() => {
                        handleMovement(index, 'up');
                      }}><FontAwesomeIcon icon={faUpLong} /></button> : ''
                    }
                    <button className="delete-button" onClick={() => {
                      handleDeleteSection(index);
                    }}><FontAwesomeIcon icon={faTrash} /></button>
                  </div>
                </div>
                {selectedSectionIndex === index && editingSection && (
                  <div className="sectionForm">
                    {section.type === 'hero' && (
                      <HeroSectionForm
                        initialContent={editingSection.content as HeroSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'horizontal-hero' && (
                      <HorizontalHeroSectionForm
                        initialContent={editingSection.content as HorizontalHeroSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'features' && (
                      <FeaturesSectionForm
                        initialContent={editingSection.content as FeaturesSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'grid-features' && (
                      <GridFeaturesSectionForm
                        initialContent={editingSection.content as GridFeaturesSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'testimonial' && (
                      <TestimonialSectionForm
                        initialContent={editingSection.content as TestimonialSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'cta' && (
                      <CtaSectionForm
                        initialContent={editingSection.content as CtaSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'accordion' && (
                      <AccordionSectionForm
                        initialContent={editingSection.content as AccordionSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'cards' && (
                      <CardsSectionForm
                        initialContent={editingSection.content as CardsSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'logo' && (
                      <LogoSectionForm
                        initialContent={editingSection.content as LogoSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'space' && (
                      <SpaceSectionForm
                        initialContent={editingSection.content as SpaceSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'social-links' && (
                      <SocialLinksForm
                        initialContent={editingSection.content as SocialLinksSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                    {section.type === 'text' && (
                      <TextSectionForm
                        initialContent={editingSection.content as TextSection['content']}
                        onSave={handleSaveSection}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
            {sections && sections.length === 0 ? <p>{t(`Your landing page is empty. Begin creating it by using the components available on the left.`)}</p> : ''}
          </div>

          {/* Column 3: Preview */}
          <div className="previewColumn">
            <h2>{t(`Preview`)}</h2>
            <Preview sections={sections} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageBuilder;