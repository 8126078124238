// src/components/Header.tsx
import React from 'react';
import './Header.css';
import { t } from 'i18next';
import i18n from '../../i18n';

const Header: React.FC = () => {
    const isAuthenticated = Boolean(localStorage.getItem('authToken')); // Check if user is authenticated

    const handleLanguageSwitch = () => {
        if (localStorage.getItem('lang')) {
            const lang = localStorage.getItem('lang');
            let newLang = '';
            if (lang === 'en') {
                newLang = 'de';
            } else {
                newLang = 'en';
            }
            i18n.changeLanguage(localStorage.getItem('lang') as string);
            localStorage.setItem('lang', newLang);
        } else {
            let language = 'en';
            i18n.changeLanguage(language);
            localStorage.setItem('lang', language);
        }
        window.location.reload();
    }

    return (
        <header className="header">
            <div className="container">
                <div className="logo-container">
                    <a href='/'>
                        <img
                            src="/logo512.png"
                            alt="Logo"
                            className="logo"
                        />
                    </a>
                </div>
                {isAuthenticated ? (
                    <>
                        <nav>
                            <a href="/">{t(`Home`)}</a>
                            <a href="/?p=product">{t(`Product`)}</a>
                            <a href="/?p=pricing">{t(`Pricing`)}</a>
                            <a href="/tzz-academy">{t(`Learn more`)}</a>
                        </nav>

                        <nav className='nav-right '>
                            <a href="/#links">{t(`My Links`)}</a>
                            <a href="/#settings">{t(`Settings`)}</a>
                            <a href="/#logout">{t(`Logout`)}</a>
                            <a href="" onClick={handleLanguageSwitch}>{localStorage.getItem('lang')?.toUpperCase()}</a>
                        </nav>
                    </>
                ) : (
                    <>
                        <nav className='nav-center '>
                            <a href="/">{t(`Home`)}</a>
                            <a href="/?p=product">{t(`Product`)}</a>
                            <a href="/?p=pricing">{t(`Pricing`)}</a>
                            <a href="/tzz-academy">{t(`Learn more`)}</a>
                        </nav>

                        <nav className='nav-right '>
                            <a href="/#otp-login">{t(`Login`)}</a>
                            <a href="" onClick={handleLanguageSwitch}>{localStorage.getItem('lang')?.toUpperCase()}</a>
                        </nav>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;