// src/Components/CardsSectionForm.tsx

import React, { useState, ChangeEvent } from 'react';
import { CardsSection, InfoCard } from '../../Types';
import ImageInputField from '../InputComponents/ImageInputField';
import ReactQuill from 'react-quill';
import { t } from 'i18next';

interface CardsSectionFormProps {
  initialContent: CardsSection['content'];
  onSave: (updatedContent: CardsSection['content']) => void;
}

const CardsSectionForm: React.FC<CardsSectionFormProps> = ({ initialContent, onSave }) => {
  const [backgroundColor, setBackgroundColor] = useState(initialContent.background_color);
  const [textColor, setTextColor] = useState(initialContent.text_color);
  const [title, setTitle] = useState(initialContent.title);
  const [itemsColor, setItemsColor] = useState(initialContent.items_color);
  const [cards, setCards] = useState<InfoCard[]>(initialContent.cards);

  const handleCardChange = (index: number, e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === 'description' && cards[index].description === value) {
      return;
    }

    const updatedCards = [...cards];
    updatedCards[index] = {
      ...updatedCards[index],
      [name]: value,
    };
    setCards(updatedCards);
  };

  const handleSave = () => {
    onSave({
      background_color: backgroundColor,
      text_color: textColor,
      items_color: itemsColor,
      title: title,
      cards: cards,
    });
  };

  const addCard = () => {
    setCards([...cards, { image_url: '', title: '', description: '', link_name: '', link_url: '' }]);
  };

  const removeCard = (index: number) => {
    setCards(cards.filter((_, i) => i !== index));
  };

  return (
    <div>
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background:`)}</small>
        <input
          type="color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
        />
        <small>{t(`Text:`)}</small>
        <input
          type="color"
          value={textColor}
          onChange={(e) => setTextColor(e.target.value)}
        />
        <small>{t(`Items:`)}</small>
        <input
          type="color"
          value={itemsColor}
          onChange={(e) => setItemsColor(e.target.value)}
        />
      </div>
      <label>
        Title:
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      {cards.map((card, index) => (
        <div key={index}>
          <label>{t(`Image URL:`)}</label>
          <ImageInputField
            name="image_url"
            value={card.image_url}
            onChange={(e, changeParams) => handleCardChange(index, e, changeParams)} />
          <label>{t(`Title:`)}</label>
          <input
            type="text"
            name="title"
            value={card.title}
            onChange={(e) => handleCardChange(index, e)}
          />
          <label>{t(`Description:`)}</label>
          <ReactQuill
            value={card.description}
            onChange={(e) => { handleCardChange(index, undefined, { name: "description", value: e }) }}
            modules={{
              toolbar: [
                ['bold', 'italic'],      // Bold and italic buttons
                [{ 'list': 'bullet' }],  // Bullet list
                ['clean']                // Remove formatting button
              ],
            }}
            formats={['bold', 'italic', 'list', 'bullet']}
            placeholder="Type something..."
          />
          <br></br><br></br><br></br><br></br>
          <label>{t(`Link Name:`)}</label>
          <input
            type="text"
            name="link_name"
            value={card.link_name}
            onChange={(e) => handleCardChange(index, e)}
          />
          <label>{t(`URL:`)}</label>
          <input
            type="text"
            name="link_url"
            value={card.link_url}
            onChange={(e) => handleCardChange(index, e)}
          />
          <button onClick={() => removeCard(index)}>Remove Card</button>
        </div>
      ))}
      <button onClick={addCard}>Add Card</button>
      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default CardsSectionForm;