// src/Components/AccordionSectionForm.tsx

import React, { useState, ChangeEvent } from 'react';
import { AccordionSection } from '../../Types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

interface AccordionSectionFormProps {
  initialContent: AccordionSection['content'];
  onSave: (updatedContent: AccordionSection['content']) => void;
}

const AccordionSectionForm: React.FC<AccordionSectionFormProps> = ({ initialContent, onSave }) => {
  const [backgroundColor, setBackgroundColor] = useState(initialContent.background_color);
  const [textColor, setTextColor] = useState(initialContent.text_color);
  const [panels, setPanels] = useState(initialContent.panels);
  const [title, setTitle] = useState(initialContent.title);

  const handlePanelChange = (index: number, e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputName?: string, inputValue?: string) => {
    let name = "";
    let value = "";
    if (e) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = inputName ?? '';
      value = inputValue ?? '';
    }

    if (name === 'description' && panels[index].content === value) {
      return;
    }

    const updatedPanels = [...panels];
    updatedPanels[index] = {
      ...updatedPanels[index],
      [name]: value,
    };
    setPanels(updatedPanels);
  };

  const handleSave = () => {
    onSave({
      title,
      background_color: backgroundColor,
      text_color: textColor,
      panels: panels,
    });
  };

  const addPanel = () => {
    setPanels([...panels, { title: '', content: '' }]);
  };

  const removePanel = (index: number) => {
    setPanels(panels.filter((_, i) => i !== index));
  };

  return (
    <div>
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background:`)}</small>
        <input
          type="color"
          value={backgroundColor}
          onChange={(e) => setBackgroundColor(e.target.value)}
        />
        <small>{t(`Text:`)}</small>
        <input
          type="color"
          value={textColor}
          onChange={(e) => setTextColor(e.target.value)}
        />
      </div>
      <label>{t(`Title:`)}</label>
      <input
        type="text"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {panels.map((panel, index) => (
        <div className='section-item' key={index}>
          <label>{t(`Panel Title:`)}</label>
          <input
            type="text"
            name="title"
            value={panel.title}
            onChange={(e) => handlePanelChange(index, e)}
          />
          <label>{t(`Panel Content:`)}</label>
          <ReactQuill
            value={panel.content}
            onChange={(e) => handlePanelChange(index, undefined, "content", e)}
            modules={{
              toolbar: [
                ['bold', 'italic'],      // Bold and italic buttons
                [{ 'list': 'bullet' }],  // Bullet list
                ['clean']                // Remove formatting button
              ],
            }}
            formats={['bold', 'italic', 'list', 'bullet']}
            placeholder="Type something..."
          />
          <br></br> <br></br><br></br>

          <button className='delete-button' onClick={() => removePanel(index)}><FontAwesomeIcon icon={faTrash} />{t(`Delete`)}</button>
        </div>
      ))}
      <button onClick={addPanel}><FontAwesomeIcon icon={faPlus} />{t(`Panel`)}</button>
      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default AccordionSectionForm;