import React, { useState } from 'react';
import { Section, HeroSection, FeaturesSection, TestimonialSection, CtaSection, AccordionSection, CardsSection, LogoSection, HorizontalHeroSection, GridFeaturesSection, TextSection, SocialLinksSection } from '../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import socialMediaSvgs from './socialMediaSvgs';

interface PreviewProps {
  sections: Section[];
}

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const generateHtmlForSection = (section: Section) => {
  const sectionStyle: React.CSSProperties = {
    backgroundColor: '',
    backgroundImage: ''
  };

  if ('background_color' in section.content) {
    sectionStyle.backgroundColor = section.content.background_color;
  }
  if ('background_image' in section.content) {
    sectionStyle.backgroundImage = section.content.background_image ? `url(${section.content.background_image})` : undefined
  }

  let additionalClasses = '';

  if ('imageOrientation' in section.content) {
    additionalClasses += 'image-' + section.content.imageOrientation;
  }

  let html = `<div class="tzz-section ${section.type}-section ${additionalClasses}" style="background-color: ${sectionStyle.backgroundColor}; background-image: ${sectionStyle.backgroundImage};">`;
  if ('title' in section.content) {
    html += `<h1 ${'text_color' in section.content ? `style="color:${section.content.text_color}"` : ''}>${section.content.title}</h1>`;
  }

  switch (section.type) {
    case 'hero':
      const heroContent = section.content as HeroSection['content'];
      html += `
          <h1 style="color: ${heroContent.text_color}">${heroContent.main_heading}</h1>
          <p style="color: ${heroContent.text_color}">${heroContent.subheading}</p>
          ${heroContent.cta_button.text ?
          `<a style="background-color: ${heroContent.button_color}; color: ${heroContent.button_text_color}" href="${heroContent.cta_button.url}">${heroContent.cta_button.text}</a>`
          : ''}
        `;
      break;

    case 'horizontal-hero':
      const horizontalHeroContent = section.content as HorizontalHeroSection['content'];
      html += `
          <div class="text-content">
            <h1 style="color: ${horizontalHeroContent.text_color}">${horizontalHeroContent.main_heading}</h1>
            <h3 style="color: ${horizontalHeroContent.text_color}">${horizontalHeroContent.subheading}</h3>
            <p style="color: ${horizontalHeroContent.text_color}">${horizontalHeroContent.description}</p>
            ${horizontalHeroContent.cta_button.text ?
          `<a style="background-color: ${horizontalHeroContent.button_color}; color: ${horizontalHeroContent.button_text_color}" href="${horizontalHeroContent.cta_button.url}">${horizontalHeroContent.cta_button.text}</a>`
          : ''}
          </div>
          <img src="${horizontalHeroContent.image}"/>
        `;
      break;

    case 'features':
      const featuresContent = section.content as FeaturesSection['content'];
      html += `
          <div class="scrollable-container">
            ${featuresContent.features.map(feature => `
              <div style="background-color: ${featuresContent.items_color};">
                ${feature.icon_url ? `<img src="${feature.icon_url}" alt="${feature.title}" />` : ''}
                <h3 style="color: ${featuresContent.text_color}">${feature.title}</h3>
                <p style="color: ${featuresContent.text_color}">${feature.description}</p>
              </div>
            `).join('')}
          </div>
        `;

      break;

    case 'grid-features':
      const gridFeaturesContent = section.content as GridFeaturesSection['content'];
      html += `
        <div class="grid-items">
          ${gridFeaturesContent.features.map(feature => `
            <div style="background-color: ${gridFeaturesContent.items_color};">
              ${feature.icon_url ? `<img src="${feature.icon_url}" alt="${feature.title}" />` : ''}
              <h3 style="color: ${gridFeaturesContent.text_color}">${feature.title}</h3>
              <p style="color: ${gridFeaturesContent.text_color}">${feature.description}</p>
            </div>
          `).join('')}
        </div>
      `;
      break;

    case 'testimonial':
      const testimonialContent = section.content as TestimonialSection['content'];
      html += `
          <div class="scrollable-container">
            ${testimonialContent.testimonials.map(testimonial => `
              <div style="background-color: ${testimonialContent.items_color};">
                ${testimonial.image_url ? `<img src="${testimonial.image_url}" alt="${testimonial.name}" />` : ''}
                <p style="color: ${testimonialContent.text_color}">"${testimonial.quote}"</p>
                <p style="color: ${testimonialContent.text_color}">${testimonial.name}</p>
              </div>
            `).join('')}
          </div>
        `;
      break;

    case 'cta':
      const ctaContent = section.content as CtaSection['content'];
      html += `
          <h3 style="color: ${ctaContent.text_color}">${ctaContent.text}</h3>
          ${ctaContent.button_text ?
          `<a style="background-color: ${ctaContent.button_color}; color: ${ctaContent.button_text_color}" href="${ctaContent.button_url}">${ctaContent.button_text}</a>`
          : ''}
        `;
      break;

    case 'accordion':
      const accordionContent = section.content as AccordionSection['content'];
      html += `
        ${accordionContent.panels.map(panel => {
        const id = "acrd-" + generateUUID();
        return `
          <div>
            <h3 style="color: ${accordionContent.text_color}" accordion-id="${id}">${panel.title}</h3>
            <div style="display: none; color: ${accordionContent.text_color}" accordion-id="${id}">${panel.content}</div>
          </div>
        `}).join('')}
      `;
      break;

    case 'cards':
      const cardsContent = section.content as CardsSection['content'];
      html += `
          <div class="scrollable-container">
            ${cardsContent.cards.map(card => `
              <div style="background-color: ${cardsContent.items_color};">
                ${card.image_url ? `<img src="${card.image_url}" alt="${card.title}"/>` : ''}
                <h3 style="color: ${cardsContent.text_color}">${card.title}</h3>
                <p style="color: ${cardsContent.text_color}">${card.description}</p>
                <a href="${card.link_url}" style="color: ${cardsContent.text_color}">${card.link_name}</a>
              </div>
            `).join('')}
          </div>
        `;
      break;
    case 'logo':
      const logoContent = section.content as LogoSection['content'];
      html += `
        <div class="logo-${logoContent.position}">
          <img src="${logoContent.image_url}" alt="Logo" />
        </div>
      `;
      break;
    case 'space':
      break;
    case 'text':
      const textContent = section.content as TextSection['content'];
      html += `
        <p style="color: ${textContent.text_color}">${textContent.text}</p>
      `;
      break;
    case 'social-links':
      const socialLinks = section.content as SocialLinksSection['content'];
      html += `
        <div class="social-links" style="display: flex; justify-content: center; gap: 20px;">
          ${socialLinks.social_links.map(link => `
            <a style="color: ${socialLinks.text_color}" href="${link.link}" target="_blank" rel="noopener noreferrer">
          ${socialMediaSvgs[link.type](socialLinks.text_color)}
            </a>
            `).join('')}
        </div>
      `;
      break;

    default:
      html = '';
  }

  html += `</div>`;
  return html;
};

const Preview: React.FC<PreviewProps> = ({ sections }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const generateIframeContent = () => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <link rel="stylesheet" href="/lnpagev1.css">
          <script src="/lnpagev1.js"></script>
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
        </head>
        <body>
          <div class="container">
          ${sections && sections.map(generateHtmlForSection).join('')}
          ${sections && sections.length === 0 ? 'Your landing page is empty. Begin creating it by using the components available on the left.' : ''}
          </div>
        </body>
      </html>
    `;
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        onClick={() => setIsPopupOpen(true)}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px',
          backgroundColor: '#1B56B3',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={faDesktop} />
      </button>
      <iframe
        title="Preview"
        style={{ width: '100%', height: '1200px', border: 'none' }}
        srcDoc={generateIframeContent()}
      />


      {isPopupOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={() => setIsPopupOpen(false)}
        >
          <iframe
            title="Desktop Preview"
            style={{
              width: '80%',
              height: '80%',
              border: 'none',
              backgroundColor: 'white',
            }}
            srcDoc={generateIframeContent()}
          />
        </div>
      )}
    </div>
  );
};

export default Preview;