import React, { ChangeEvent, useState } from 'react';
import { TextSection } from '../../Types';
import ReactQuill from 'react-quill';
import { t } from 'i18next';


interface TextFormProps {
    initialContent: TextSection['content'];
    onSave: (updatedContent: TextSection['content']) => void;
}

const TextSectionForm: React.FC<TextFormProps> = ({ initialContent, onSave }) => {
    const [content, setContent] = useState<TextSection['content']>(initialContent);

    // Handle changes for title, background color, and text color
    const handleBasicChange = (e?: ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => {
        let { name, value } = { name: '', value: '' };
        if (e) {
            name = e.target.name;
            value = e.target.value;
        } else if (changeParams) {
            name = changeParams.name;
            value = changeParams.value;
        }

        setContent(prevContent => ({ ...prevContent, [name]: value }));
    };

    // Save the content
    const handleSave = () => {
        onSave(content);
    };

    return (
        <div>
            <label>{t(`Colors:`)}</label>
            <div className="grid-div">
                <small>{t(`Background Color:`)}</small>
                <input
                    type="color"
                    name="background_color"
                    value={content.background_color}
                    onChange={handleBasicChange}
                />
                <small>{t(`Text Color:`)}</small>
                <input
                    type="color"
                    name="text_color"
                    value={content.text_color}
                    onChange={handleBasicChange}
                />
            </div>
            <label>{t(`Title:`)}</label>
            <input
                type="text"
                name="title"
                value={content.title}
                onChange={handleBasicChange}
            />
            <ReactQuill
                value={content.text}
                onChange={(e) => handleBasicChange(undefined, { name: "text", value: e })}
                modules={{
                    toolbar: [
                        ['bold', 'italic'],      // Bold and italic buttons
                        [{ 'list': 'bullet' }],  // Bullet list
                        ['clean']                // Remove formatting button
                    ],
                }}
                formats={['bold', 'italic', 'list', 'bullet']}
                placeholder="Type something..."
            />
            <br></br> <br></br><br></br>

            <hr />
            <button onClick={handleSave}>Save</button>
        </div>
    );
};

export default TextSectionForm;
