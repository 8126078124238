// src/components/Logo.tsx
import React, { ChangeEvent } from 'react';
import { LogoSection } from '../../Types';  // Ensure the LogoSection type is imported
import ImageInputField from '../InputComponents/ImageInputField';
import { t } from 'i18next';

interface LogoSectionFormProps {
  initialContent: LogoSection['content'];
  onSave: (updatedContent: LogoSection['content']) => void;
}

const LogoSectionForm: React.FC<LogoSectionFormProps> = ({ initialContent, onSave }) => {
  const [content, setContent] = React.useState<LogoSection['content']>(initialContent);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => {
    if (changeParams) {
      const { name, value } = changeParams;
      setContent((prevContent) => ({ ...prevContent, [name]: value }));
    } else {
      const { name, value } = e.target;
      setContent((prevContent) => ({ ...prevContent, [name]: value }));
    }
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleSave = () => {
    onSave(content);
  };

  return (
    <div>
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background Color:`)}</small>
        <input
          type="color"
          name="background_color"
          value={content.background_color}
          onChange={handleChange}
        />
      </div>
      <label>{t(`Logo Image URL:`)}</label>

      <ImageInputField
        name="image_url"
        value={content.image_url}
        onChange={handleChange} />

      <label>{t(`Position:`)}</label>
      <select
        name="position"
        value={content.position}
        onChange={handleSelectChange}
      >
        <option value="left">{t(`Left`)}</option>
        <option value="center">{t(`Center`)}</option>
        <option value="right">{t(`Right`)}</option>
      </select>

      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default LogoSectionForm;