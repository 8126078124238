import React, { useState, useEffect, useRef } from 'react';
import imageCompression from 'browser-image-compression';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useMessage } from '../../../PopUp/MessageContex';
import { t } from 'i18next';

type ImageInputFieldProps = {
    name: string;
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => void;
};

const MAX_IMAGE_SIZE = 0.1; //mb

const isBase64Image = (value: string) => {
    return value.startsWith('data:image');
};

const ImageInputField: React.FC<ImageInputFieldProps> = ({ name = '', value = '', onChange }) => {
    const { showMessage } = useMessage();
    const [image, setImage] = useState<string>(value);
    const [isBase64, setIsBase64] = useState<boolean>(isBase64Image(value));
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        // Set initial value from props
        setImage(value);
        setIsBase64(isBase64Image(value));
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setImage(newValue);
        setIsBase64(isBase64Image(newValue));
        onChange(e);
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setIsProcessing(true); // Start processing
            const compressedFile = await compressImage(file);
            const base64 = await toBase64(compressedFile);
            setImage(base64);
            setIsBase64(true);
            setIsProcessing(false); // Processing done
            onChange(e, { name, value: base64 });
        }
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const emptyImage = () => {
        setImage('');
        setIsBase64(false);
        if (textInputRef.current) {
            const temp: React.ChangeEvent<HTMLInputElement> = {
                target: textInputRef.current,
                nativeEvent: new Event(""),
                currentTarget: textInputRef.current,
                bubbles: false,
                cancelable: false,
                defaultPrevented: false,
                eventPhase: 0,
                isTrusted: true,
                preventDefault: function (): void {
                    throw new Error('Function not implemented.');
                },
                isDefaultPrevented: function (): boolean {
                    throw new Error('Function not implemented.');
                },
                stopPropagation: function (): void {
                    throw new Error('Function not implemented.');
                },
                isPropagationStopped: function (): boolean {
                    throw new Error('Function not implemented.');
                },
                persist: function (): void {
                    throw new Error('Function not implemented.');
                },
                timeStamp: 0,
                type: ''
            };
            onChange(temp, { name, value: '' });
        }
    };

    const compressImage = async (file: File) => {
        const options = {
            maxSizeMB: MAX_IMAGE_SIZE, // Adjust to keep file under 100KB
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        try {
            const compressedFile = await imageCompression(file, options);
            return compressedFile;
        } catch (error) {
            showMessage('error', t('Image compression error. Please try another image or optimize before uploading'));
            console.error('Image compression error:', error);
            return file;
        }
    };

    const toBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleAddImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically trigger file input
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = image;
        link.download = 'image.png';
        link.click();
    };

    return (
        <div className='image-input-field' style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input
                ref={textInputRef}
                type="text"
                name={name}
                value={image}
                disabled={isBase64 || isProcessing}
                onChange={handleInputChange}
                placeholder="Add image URL or upload"
                style={{ flex: 1, marginRight: '10px', opacity: isProcessing ? 0.5 : 1 }}
            />
            <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <label htmlFor="file-upload">
                <button title='Upload image' style={{ width: '36px', height: '36px', padding: '0' }} type="button" onClick={handleAddImageClick} disabled={isProcessing}>
                    <FontAwesomeIcon icon={faUpload} />
                </button>
                {isBase64 && (
                    <>
                        <button title='Download the uploaded image' style={{ width: '36px', height: '36px', padding: '0', marginLeft: '5px' }} onClick={handleDownload} type="button">
                            <FontAwesomeIcon icon={faDownload} />
                        </button>
                        <button title='Delete the image' onClick={emptyImage} type="button" style={{ width: '36px', height: '36px', padding: '0', marginLeft: '5px', backgroundColor: '#BA4B4B' }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </>
                )
                }
            </label>
            {
                isProcessing && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#333',
                        }}
                    >{t(`Preparing...`)}</div>
                )
            }
        </div >
    );
};

export default ImageInputField;
