// src/pages/UrlInputPage.tsx
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to handle route parameters
import './UrlInputPageGuest.css'; // Import CSS for styling
import UrlInputGuest from '../../components/UrlInputGuest/UrlInputGuest';
import { t } from 'i18next';

const UrlInputPageGuest: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Extract URL ID from route parameters
    const navigate = useNavigate(); // Initialize navigate function

    const onShorten = (url: string) => {
        navigate("/links");
    };

    return (
        <div className="url-input-page-guest">
            <h2>{t(`Create your powerful link`)}</h2>
            <small>{t(`You are using this tool without an account, editing and statistic won't be available!`)}</small><br></br>
            <UrlInputGuest
            />
        </div>
    );
};

export default UrlInputPageGuest;