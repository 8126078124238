import { t } from 'i18next';
import React, { useState } from 'react';

interface ConfirmationDialogProps {
    title?: string; // Optional title
    message: string;
    inputRequired?: boolean; // Whether the input is required to confirm
    inputLabel?: string; // Optional label for the input
    expectedInputValue?: string; // The value that should be typed to confirm
    onConfirm: () => void; // Callback when the user confirms
    onCancel: () => void; // Callback when the user cancels
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    title = "Confirmation", // Default title
    message,
    inputRequired = false,
    inputLabel = "Type to confirm",
    expectedInputValue = "",
    onConfirm,
    onCancel,
}) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [inputError, setInputError] = useState<boolean>(false); // State for tracking input errors

    const handleConfirm = () => {
        if (inputRequired && expectedInputValue !== inputValue) {
            setInputError(true); // Set error state to true if the input doesn't match
            return;
        }
        onConfirm();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setInputError(false); // Clear error state when input changes
    };

    return (
        <>
            {/* Background overlay */}
            <div style={styles.overlay} />

            {/* Dialog box */}
            <div style={styles.dialog}>
                <h2 style={styles.title}>{title}</h2>
                <div>
                    <p>{message}</p>
                    {inputRequired && (
                        <div style={styles.inputContainer}>
                            <label>{inputLabel}</label>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                style={{
                                    ...styles.input,
                                    borderColor: inputError ? 'red' : '#ccc', // Conditionally apply red border
                                }}
                            />
                            {inputError && (
                                <p style={styles.errorMessage}>{t(`The input does not match the required value.`)}</p>
                            )}
                        </div>
                    )}
                </div>
                <div style={styles.buttons}>
                    <button onClick={onCancel} style={styles.cancelButton}>Cancel</button>
                    <button onClick={handleConfirm} style={styles.confirmButton}>Confirm</button>
                </div>
            </div>
        </>
    );
};

// Correctly typed styles object
const styles: { [key: string]: React.CSSProperties } = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Gray and transparent
        zIndex: 999, // Ensure it is behind the dialog
    },
    dialog: {
        padding: '30px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        width: '400px', // Increased width
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000, // Higher than the overlay
        textAlign: 'left', // Left-aligned content
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    },
    title: {
        margin: 0,
        paddingBottom: '15px',
        borderBottom: '1px solid #ddd',
        fontSize: '1.5rem',
        textAlign: 'left',
    },
    inputContainer: {
        marginTop: '10px',
    },
    input: {
        marginTop: '10px',
        padding: '8px',
        width: '100%',
        border: '1px solid #ccc'
    },
    errorMessage: {
        color: 'red',
        marginTop: '5px',
        fontSize: '0.9rem',
    },
    buttons: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end', // Align buttons to the right
        gap: '10px', // Add space between buttons
    },
    cancelButton: {
        backgroundColor: '#E9ECEF',
        color: 'black',
        border: '1px solid #ccc',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    confirmButton: {
        backgroundColor: '#1B56B3', // Red color for Confirm
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        cursor: 'pointer',
    },
};

export default ConfirmationDialog;
