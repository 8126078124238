import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/ApiService';
import { UrlItemDTO, GetUrlsResponseDTO } from '../../services/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToEye, faChartArea, faEdit, faQrcode, faTrash } from '@fortawesome/free-solid-svg-icons';
import UrlClicks from './UrlClicks';
import { useMessage } from '../PopUp/MessageContex';
import { useConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialogProvider';
import QrCodePopup from '../QrCode/QrCodePopup';
import { t } from 'i18next';

const UrlList: React.FC = () => {
    const { showMessage } = useMessage();
    const { showConfirmation } = useConfirmationDialog()
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [qrLink, setRrLink] = useState('');
    const [urls, setUrls] = useState<UrlItemDTO[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [searchQuery, setSearchQuery] = useState<string>(''); // State for search query
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchUrls = async () => {
        setIsLoading(true);
        const response: GetUrlsResponseDTO = await ApiService.getUrls(currentPage, pageSize, searchQuery);
        setIsLoading(false);

        if (response.status === 'success') {
            setUrls(response.urls);
            setTotalPages(response.totalPages || 1);
        } else {
            setErrorMessage(response.message || 'Failed to load URLs');
        }
    };

    useEffect(() => {
        fetchUrls();
    }, [currentPage, searchQuery]); // Dependency array includes searchQuery

    const handleStatistics = (urlId: string) => {
        navigate(`/statistics/${urlId}`);
    };

    const handleEdit = (urlId: string) => {
        navigate(`/edit-url/${urlId}`);
    };

    const handleDelete = async (urlId: string) => {
        const confirmation = await showConfirmation(t("Are you sure for deleting '") + urlId + "'?");

        if (!confirmation) {
            return;
        }

        const response = await ApiService.deleteUrl(urlId);
        showMessage(response.status, response.message);
        fetchUrls();
    };

    const handleAddNew = () => {
        navigate('/add-url');
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="url-list">
            <h1>{t(`My URLs`)}</h1>
            <button className="add-new-button" onClick={handleAddNew}>Add New Link</button>
            <input
                type="text"
                placeholder="Search by short URL..."
                className="search-bar"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
            />
            {isLoading ? (
                <div className="loader">{t(`Loading...`)}</div>
            ) : errorMessage ? (
                <p className="error-message">{errorMessage}</p>
            ) : (
                <>
                    <table className="url-table">
                        <thead>
                            <tr>
                                <th>{t(`Destination URL/Landing`)}</th>
                                <th>{t(`Short URL`)}</th>
                                <th>{t(`Creation Date`)}</th>
                                <th>{t(`Clicks`)}</th>
                                <th>{t(`Actions`)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {urls.map((url, index) => (
                                <tr key={index}>
                                    <td>{url.is_landing_page === 1 ? 'Landing Page' : url.original_url}</td>
                                    <td>
                                        <a href={url.short_url} target="_blank" rel="noopener noreferrer">
                                            {url.short_url}
                                        </a>
                                    </td>
                                    <td>{new Date(url.created_at).toLocaleDateString()}</td>
                                    <td><UrlClicks urlId={url.short_url}></UrlClicks></td>
                                    <td>
                                        <button className="action-button" onClick={() => handleStatistics(url.short_url)}>
                                            <FontAwesomeIcon icon={faChartArea} />
                                        </button>
                                        <button className="action-button" onClick={() => handleEdit(url.short_url)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button className="action-button" onClick={() => {
                                            setRrLink('https://tzz.at/' + url.short_url);
                                            setIsPopupOpen(true);
                                        }}>
                                            <FontAwesomeIcon icon={faQrcode} />
                                        </button>
                                        <button className="action-button delete-button" onClick={() => handleDelete(url.short_url)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}

            <QrCodePopup
                link={qrLink}
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
            />
        </div>
    );
};

export default UrlList;