import React, { useState } from 'react';
import './PricingSection.css';
import { t } from 'i18next';

function PricingSection() {
    // State to track if annual or monthly pricing is selected
    const [isAnnual, setIsAnnual] = useState(true);

    return (
        <section id="product" className="pricing-section">
            <h2>{t(`Choose the Plan that Fits Your Needs`)}</h2>
            <p>{t(`From free access to premium control, our pricing plans offer flexibility and value.`)}</p>
            <div className="pricing-toggle-buttons">
                <button
                    className={`toggle-button ${isAnnual ? 'active' : ''}`}
                    onClick={() => setIsAnnual(true)}
                >
                    {t(`Annual`)}
                </button>
                <button
                    className={`toggle-button ${!isAnnual ? 'active' : ''}`}
                    onClick={() => setIsAnnual(false)}
                >
                    {t(`Monthly`)}
                </button>
            </div>
            <div className="pricing-options">
                <div className="pricing-option">
                    <h3>{t(`Free Plan`)}</h3>
                    <p>{t(`€0`)}</p>
                    <ul>
                        <li>{t(`Up to 5 links per month`)}</li>
                        <li>{t(`QR Codes included`)}</li>
                        <li>{t(`1 custom slug`)}</li>
                        <li>{t(`1 custom landing page with tzz brand`)}</li>
                        <li>{t(`2 weeks of statistics`)}</li>
                    </ul>
                </div>
                <div className="pricing-option">
                    <h3>{t(`Starter Plan`)}</h3>
                    <p>{isAnnual ? t('€50/year') : t('Only annual pricing available')}</p>
                    <ul>
                        <li>{t(`100 links per month`)}</li>
                        <li>{t(`QR Codes included`)}</li>
                        <li>{t(`10 custom slugs`)}</li>
                        <li>{t(`10 custom landing pages with custom brand colors`)}</li>
                        <li>{t(`1 month of statistics`)}</li>
                    </ul>
                </div>
                <div className="pricing-option">
                    <h3>{t(`Pro Plan`)}</h3>
                    <p>{isAnnual ? t('€120/year (Save 20%)') : t('€15/month')}</p>
                    <ul>
                        <li>{t(`1,000 links per month`)}</li>
                        <li>{t(`QR Codes included`)}</li>
                        <li>{t(`50 custom slugs`)}</li>
                        <li>{t(`50 custom landing pages with custom brand colors`)}</li>
                        <li>{t(`2 months of statistics`)}</li>
                    </ul>
                </div>
                <div className="pricing-option">
                    <h3>{t(`Business Plan`)}</h3>
                    <p>{isAnnual ? t('€280/year (Save 20%)') : t('€35/month')}</p>
                    <ul>
                        <li>{t(`5,000 links per month`)}</li>
                        <li>{t(`QR Codes included`)}</li>
                        <li>{t(`500 custom slugs`)}</li>
                        <li>{t(`500 custom landing pages with custom brand colors`)}</li>
                        <li>{t(`3 months of statistics`)}</li>
                    </ul>
                </div>
            </div>
            <p className="user-guidance">{t(`To get started, simply sign in using One Time Password (OTP) authentication. Once you're in, you'll be able to explore our platform and select the pricing plan that best suits your needs.`)}</p>
        </section>
    );
}

export default PricingSection;