import React, { ChangeEvent } from 'react';
import { SpaceSection } from '../../Types';
import { t } from 'i18next';

interface SpaceSectionFormProps {
    initialContent: SpaceSection['content'];
    onSave: (updatedContent: SpaceSection['content']) => void;
}

const SpaceSectionForm: React.FC<SpaceSectionFormProps> = ({ initialContent, onSave }) => {
    const [content, setContent] = React.useState<SpaceSection['content']>(initialContent);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, changeParams?: { name: string, value: string }) => {
        let { name, value } = { name: '', value: '' };
        if (changeParams) {
            name = changeParams.name;
            value = changeParams.value;
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        setContent((prevContent) => ({ ...prevContent, [name]: value }));
    };

    const handleSave = () => {
        onSave(content);
    };

    return (
        <div>
            <label>{t(`Colors:`)}</label>
            <div className="grid-div">
                <small>{t(`Background:`)}</small>
                <input
                    name="background_color"
                    type="color"
                    value={content.background_color}
                    onChange={(e) => handleChange(e)}
                />
            </div>
            <hr></hr>
            <button onClick={handleSave}>Save</button>
        </div>
    )
}

export default SpaceSectionForm;