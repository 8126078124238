// src/components/Footer.tsx
import React from 'react';
import './Footer.css';
import { t } from 'i18next';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <p>{t(`&copy; 2024 tzz.at. All rights reserved.`)}</p>
            <div className="footer-links">
                <a href="/tzz-academy">{t(`TZZ Academy`)}</a>
                <a href="/#privacy-policy">{t(`Privacy Policy`)}</a>
                <a href="/#terms-and-conditions">{t(`Terms and Conditions`)}</a>
                <a href="mailto:support@tzz.at">{t(`Contact Support`)}</a>
            </div>
        </footer>
    );
};

export default Footer;
