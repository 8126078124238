// src/pages/Settings.tsx
import React, { useEffect, useState } from 'react';
import PricingSectionAdmin from '../../components/PricingSection/PricingSectionAdmin';
import { SubscriptionDTO } from '../../services/dtos';
import ApiService from '../../services/ApiService';
import './Settings.css'
import { useConfirmationDialog } from '../../components/ConfirmationDialog/ConfirmationDialogProvider';
import { useMessage } from '../../components/PopUp/MessageContex';
import { t } from 'i18next';

const Settings: React.FC = () => {
    const { showConfirmation } = useConfirmationDialog();
    const { showMessage } = useMessage();
    const [currentPlan, setCurrentPlan] = useState<SubscriptionDTO>({
        status: 'error', // Assuming status can only be 'success' or 'error'
        settings: {
            stripeKey: '',
            plans: []
        },
        plan: '',
        subscription_id: '',
        monthly_links: 0,
        monthly_links_limit: 0,
        landing_pages: 0,
        landing_pages_limit: 0,
        custom_slug: 0,
        custom_slug_limit: 0,
        statistics_weeks: 0,
        statistics_weeks_limit: 0,
        ends_at: '',
        is_canceled: 0,
    });

    const fetchPlan = async () => {
        const plan = await ApiService.getCurrentPlan();
        setCurrentPlan(plan);
    };

    const handleDelete = async () => {
        const deleteAnswer = await showConfirmation(
            t('Deleting your account is an irreversible action. Any active subscription will be canceled at the end of its current billing cycle. All existing URLs and landing pages will be permanently removed, and visitors will see a 404 error. Please confirm to proceed.'),
            true,
            t("Type 'DeleteAccount' to confirm"),
            t("DeleteAccount"),
            t("Account Delete"))

        if (deleteAnswer) {
            const response = await ApiService.deleteAccount();
            showMessage(response.status, response.message);
            if (response.status === 'success') {
                ApiService.logOut();
            }
        }
    }

    useEffect(() => {
        fetchPlan();
    }, []);

    return (
        <div className="settings-page">
            <h1>{t(`Settings`)}</h1>
            <section className="stats-section">
                <h2>{t(`Consumptions`)}</h2>
                <div className="stats">
                    <div className="stat">
                        <h3>{currentPlan.monthly_links + '/' + currentPlan.monthly_links_limit}</h3>
                        <p>{t(`URLs`)}</p>
                    </div>
                    <div className="stat">
                        <h3>{currentPlan.landing_pages + '/' + currentPlan.landing_pages_limit}</h3>
                        <p>{t(`Landing Pages`)}</p>
                    </div>
                    <div className="stat">
                        <h3>{currentPlan.custom_slug + '/' + currentPlan.custom_slug_limit}</h3>
                        <p>{t(`Custom Slugs`)}</p>
                    </div>
                </div>
            </section>

            <PricingSectionAdmin />

            <section className="account-delete-section">
                <h2>{t(`Account Deletion`)}</h2>
                <div className="delete-options">
                    <div className="warning">
                        <h3>{t(`Warning`)}</h3>
                        <p><strong>{t(`Please note that deleting your account is an irreversible action.`)}</strong> Once the account is deleted:</p>

                        <ul>
                            <li>{t(`Any active subscription will be canceled, and it will not renew after the current billing cycle. You will retain access to subscription features until the expiration of your current subscription.`)}</li>
                            <li>All associated links, landing pages, and any other content will be permanently removed. Visitors to your URLs and landing pages will see a <strong>{t(`404 Not Found`)}</strong> error.</li>
                        </ul>

                        <p>{t(`If you wish to proceed, please confirm your request.`)}</p>

                    </div>
                    <div className="delete-action">
                        <button className="delete-button" onClick={handleDelete}>
                            Delete Account
                        </button>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Settings;