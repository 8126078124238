import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/locales/en/translation.json';
import de from './i18n/locales/de/translation.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
} as const; // Define as constant to infer types

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false, // Not needed for React
    },
  });

export default i18n;
