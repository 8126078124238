import React, { useState } from 'react';
import { TestimonialSection } from '../../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ImageInputField from '../InputComponents/ImageInputField';
import { t } from 'i18next';

interface TestimonialSectionFormProps {
  initialContent: TestimonialSection['content'];
  onSave: (updatedContent: TestimonialSection['content']) => void;
}

const TestimonialSectionForm: React.FC<TestimonialSectionFormProps> = ({ initialContent, onSave }) => {
  const [testimonials, setTestimonials] = useState(initialContent.testimonials);
  const [bgColor, setBgColor] = useState(initialContent.background_color);
  const [bgImage, setBgImage] = useState(initialContent.background_image);
  const [textColor, setTextColor] = useState(initialContent.text_color);
  const [itemsColor, setItemsColor] = useState(initialContent.items_color);
  const [title, setTitle] = useState(initialContent.title);

  // Handle changes for testimonial fields
  const handleTestimonialChange = (index: number, e: React.ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const updatedTestimonials = [...testimonials];
    updatedTestimonials[index] = {
      ...updatedTestimonials[index],
      [name]: value,
    };
    setTestimonials(updatedTestimonials);
  };

  // Add a new testimonial
  const addTestimonial = () => {
    setTestimonials((prevTestimonials) => [
      ...prevTestimonials,
      { quote: '', name: '', image_url: '' }, // Default new testimonial
    ]);
  };

  // Remove a testimonial
  const removeTestimonial = (index: number) => {
    setTestimonials((prevTestimonials) =>
      prevTestimonials.filter((_, i) => i !== index)
    );
  };

  // Save changes
  const handleSave = () => {
    onSave({
      title: title,
      background_color: bgColor,
      background_image: bgImage,
      text_color: textColor,
      items_color: itemsColor,
      testimonials: testimonials,
    });
  };

  return (
    <div className="section-form">
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background:`)}</small>
        <input
          type="color"
          value={bgColor}
          onChange={(e) => setBgColor(e.target.value)}
        />
        <small>{t(`Text:`)}</small>
        <input
          type="color"
          value={textColor}
          onChange={(e) => setTextColor(e.target.value)}
        />
        <small>{t(`Items:`)}</small>
        <input
          type="color"
          value={itemsColor}
          onChange={(e) => setItemsColor(e.target.value)}
        />

      </div>
      <label>{t(`Title:`)}</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <label>{t(`Background Image URL:`)}</label>
      <input
        type="text"
        value={bgImage}
        onChange={(e) => setBgImage(e.target.value)}
      />

      <div>
        <h4>{t(`Testimonials`)}</h4>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="section-item">
            <label>{t(`Quote:`)}</label>
            <input
              type="text"
              name="quote"
              value={testimonial.quote}
              onChange={(e) => handleTestimonialChange(index, e)}
            />
            <label>{t(`Name:`)}</label>
            <input
              type="text"
              name="name"
              value={testimonial.name}
              onChange={(e) => handleTestimonialChange(index, e)}
            />
            <label>{t(`Image URL:`)}</label>
            <ImageInputField
              name="image_url"
              value={testimonial.image_url}
              onChange={(e, changeParams) => handleTestimonialChange(index, e, changeParams)} />
            <button onClick={() => removeTestimonial(index)}>Remove</button>
          </div>
        ))}
        <button onClick={addTestimonial}><FontAwesomeIcon icon={faPlus} />{t(`Testimonial`)}</button>
      </div>

      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default TestimonialSectionForm;