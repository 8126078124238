import React from 'react';
import './PricingComparison.css';
import { t } from 'i18next';

function PricingComparison() {
    return (
        <section className="pricing-comparison-section">
            <h2>{t(`Compare Our Pricing Plans`)}</h2>
            <table className="pricing-table">
                <thead>
                    <tr>
                        <th>{t(`Feature`)}</th>
                        <th>{t(`Free Plan`)}</th>
                        <th>{t(`Starter Plan`)}</th>
                        <th>{t(`Pro Plan`)}</th>
                        <th>{t(`Business Plan`)}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {t(`Links per Month`)}
                            <span className="info-icon" title={t(`The maximum number of short links you can create each month.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`5`)}</td>
                        <td>{t(`100`)}</td>
                        <td>{t(`1,000`)}</td>
                        <td>{t(`5,000`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Change of Destination`)}
                            <span className="info-icon" title={t(`Ability to change the destination URL of your short link after it's created.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`QR Codes Included`)}
                            <span className="info-icon" title={t(`Generate a QR code for each short link, making it easy to share and scan.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Custom Slugs`)}
                            <span className="info-icon" title={t(`Customize the ending of your short link for brand consistency.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`1`)}</td>
                        <td>{t(`10`)}</td>
                        <td>{t(`50`)}</td>
                        <td>{t(`500`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Custom Landing Pages`)}
                            <span className="info-icon" title={t(`Create landing pages for your short links with custom branding and messaging.`)}>{t(`i`)}</span>
                        </td>
                        <td>1 (tzz brand)</td>
                        <td>10 (custom brand colors)</td>
                        <td>50 (custom brand colors)</td>
                        <td>500 (custom brand colors)</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Custom Logo on Landing Pages`)}
                            <span className="info-icon" title={t(`Ability to add your own logo to landing pages. Available from Starter Plan and above.`)}>{t(`i`)}</span>
                        </td>
                        <td>✓ (tzz brand on top)</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Landing Page Templates`)}
                            <span className="info-icon" title={t(`Use the predefined templates for different industries and profiles.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Statistics Period`)}
                            <span className="info-icon" title={t(`The duration for which you can track and analyze link performance.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`2 weeks`)}</td>
                        <td>{t(`1 month`)}</td>
                        <td>{t(`2 months`)}</td>
                        <td>{t(`3 months`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`Priority Support`)}
                            <span className="info-icon" title={t(`Access to faster response times and priority handling of your support queries.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`-`)}</td>
                        <td>{t(`-`)}</td>
                        <td>{t(`✓`)}</td>
                        <td>{t(`✓`)}</td>
                    </tr>
                    <tr>
                        <td>
                            {t(`User Management`)}
                            <span className="info-icon" title={t(`User management is not available in any of the current plans.`)}>{t(`i`)}</span>
                        </td>
                        <td>{t(`-`)}</td>
                        <td>{t(`-`)}</td>
                        <td>(Comming soon)</td>
                        <td>(Comming soon)</td>
                    </tr>
                </tbody>
            </table>
            <p className="pricing-note">{t(`To get started, simply sign in using One Time Password (OTP) authentication. Once you're in, you'll be able to explore our platform and select the pricing plan that best suits your needs.`)}</p>
        </section>
    );
}

export default PricingComparison;