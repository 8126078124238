// src/App.tsx
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Links from './pages/Links/Links';
import Settings from './pages/Settings/Settings';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';
import OtpLogin from './pages/OtpLogin/OtpLogin';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import UrlInputPage from './pages/UrlInputPage/UrlInputPage';
import StatisticsPage from './pages/StatisticsPage/StatisticsPage';
import LogOut from './components/LogOut/LogOut';
import { MessageProvider } from './components/PopUp/MessageContex';
import MessagePopup from './components/PopUp/MessagePopup';
import { ConfirmationDialogProvider } from './components/ConfirmationDialog/ConfirmationDialogProvider';
import CookieConsent from 'react-cookie-consent';
import { t } from 'i18next';
import i18n from './i18n';

declare global {
    interface Window {
        language: string;
    }
}

const App: React.FC = () => {
    const [isAuthenticated] = useState<boolean>(Boolean(localStorage.getItem('authToken')));

    useEffect(() => {
        if (localStorage.getItem('lang')) {
            i18n.changeLanguage(localStorage.getItem('lang') as string);
        } else {
            let language = 'en';
            if ('language' in window) {
                language = ["de", "at", "ch"].includes(window.language.toLowerCase()) ? 'de' : 'en';
            }
            i18n.changeLanguage(language);
            localStorage.setItem('lang', language);
        }
    }, []);

    return (
        <Router>
            <div className="app">
                <Header />
                <div className="main-content">
                    <MessageProvider>
                        <ConfirmationDialogProvider>
                            <MessagePopup />
                            <div className="container">
                                <Routes>
                                    <Route path="/" element={<LandingPage />} />
                                    <Route path="/otp-login" element={<OtpLogin />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                                    {isAuthenticated && (
                                        <>
                                            <Route path="/logout" element={<LogOut />} />
                                            <Route path="/links" element={<Links />} />
                                            <Route path="/statistics/:id" element={<StatisticsPage />} />
                                            <Route path="/settings" element={<Settings />} />
                                            <Route path="/add-url" element={<UrlInputPage />} />
                                            <Route path="/edit-url/:id" element={<UrlInputPage />} />
                                        </>
                                    )}
                                </Routes>
                            </div>
                        </ConfirmationDialogProvider>
                    </MessageProvider>
                </div>
                <Footer />
                <CookieConsent
                    location="bottom"
                    buttonText="I understand"
                    cookieName="tzzAtCookieConsent"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                >
                    {t(`This website uses cookies to enhance the user experience.`)}
                    <a href="/privacy-policy" style={{ color: "#FFD700" }}>{t(`Learn more`)}</a>
                </CookieConsent>
            </div>
        </Router>
    );
};

export default App;