import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../services/ApiService';
import { RedirectionConditionDTO, CreateUrlRequestDTO, UpdateUrlRequestDTO } from '../../services/dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import './UrlInput.css';
import LandingPageBuilder from '../LandingPageBuilder/Builder/LandingPageBuilder';
import { Section } from '../LandingPageBuilder/Types';
import { useMessage } from "../PopUp/MessageContex"; // Import the custom hook
import { t } from 'i18next';
import { useConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialogProvider';

type ConditionType = 'device' | 'browser' | 'os' | 'country';

const conditionOptions: Record<ConditionType, string[]> = {
    device: ['Mobile', 'Desktop'],
    browser: ['Chrome', 'Firefox', 'Safari', 'Edge', 'Opera', 'Other'],
    os: ['Windows', 'Mac OS', 'Linux', 'Android', 'iOS'],
    country: [
        'AF', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ',
        'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV',
        'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF', 'TD', 'CL', 'CN',
        'CX', 'CC', 'CO', 'KM', 'CD', 'CG', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK',
        'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'SZ', 'ET', 'FK', 'FO', 'FJ', 'FI',
        'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU',
        'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR',
        'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW',
        'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MG', 'MW', 'MY', 'MV',
        'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA',
        'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MK', 'MP',
        'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA',
        'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA',
        'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK',
        'SD', 'SR', 'SJ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT',
        'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE',
        'VN', 'VG', 'VI', 'WF', 'EH', 'XK', 'YE', 'ZM', 'ZW'
    ]
};

const countryCodes: Record<string, string> = {
    'AF': 'Afghanistan', 'AL': 'Albania', 'DZ': 'Algeria', 'AS': 'American Samoa', 'AD': 'Andorra',
    'AO': 'Angola', 'AI': 'Anguilla', 'AQ': 'Antarctica', 'AG': 'Antigua and Barbuda', 'AR': 'Argentina',
    'AM': 'Armenia', 'AW': 'Aruba', 'AU': 'Australia', 'AT': 'Austria', 'AZ': 'Azerbaijan',
    'BS': 'Bahamas', 'BH': 'Bahrain', 'BD': 'Bangladesh', 'BB': 'Barbados', 'BY': 'Belarus',
    'BE': 'Belgium', 'BZ': 'Belize', 'BJ': 'Benin', 'BM': 'Bermuda', 'BT': 'Bhutan',
    'BO': 'Bolivia', 'BQ': 'Bonaire, Sint Eustatius and Saba', 'BA': 'Bosnia and Herzegovina', 'BW': 'Botswana', 'BV': 'Bouvet Island',
    'BR': 'Brazil', 'IO': 'British Indian Ocean Territory', 'BN': 'Brunei Darussalam', 'BG': 'Bulgaria', 'BF': 'Burkina Faso',
    'BI': 'Burundi', 'CV': 'Cabo Verde', 'KH': 'Cambodia', 'CM': 'Cameroon', 'CA': 'Canada',
    'KY': 'Cayman Islands', 'CF': 'Central African Republic', 'TD': 'Chad', 'CL': 'Chile', 'CN': 'China',
    'CX': 'Christmas Island', 'CC': 'Cocos (Keeling) Islands', 'CO': 'Colombia', 'KM': 'Comoros', 'CD': 'Congo, Democratic Republic of the',
    'CG': 'Congo', 'CK': 'Cook Islands', 'CR': 'Costa Rica', 'CI': "Côte d'Ivoire", 'HR': 'Croatia',
    'CU': 'Cuba', 'CW': 'Curaçao', 'CY': 'Cyprus', 'CZ': 'Czech Republic', 'DK': 'Denmark',
    'DJ': 'Djibouti', 'DM': 'Dominica', 'DO': 'Dominican Republic', 'EC': 'Ecuador', 'EG': 'Egypt',
    'SV': 'El Salvador', 'GQ': 'Equatorial Guinea', 'ER': 'Eritrea', 'EE': 'Estonia', 'SZ': 'Eswatini',
    'ET': 'Ethiopia', 'FK': 'Falkland Islands (Malvinas)', 'FO': 'Faroe Islands', 'FJ': 'Fiji', 'FI': 'Finland',
    'FR': 'France', 'GF': 'French Guiana', 'PF': 'French Polynesia', 'TF': 'French Southern Territories', 'GA': 'Gabon',
    'GM': 'Gambia', 'GE': 'Georgia', 'DE': 'Germany', 'GH': 'Ghana', 'GI': 'Gibraltar',
    'GR': 'Greece', 'GL': 'Greenland', 'GD': 'Grenada', 'GP': 'Guadeloupe', 'GU': 'Guam',
    'GT': 'Guatemala', 'GG': 'Guernsey', 'GN': 'Guinea', 'GW': 'Guinea-Bissau', 'GY': 'Guyana',
    'HT': 'Haiti', 'HM': 'Heard Island and McDonald Islands', 'VA': 'Holy See', 'HN': 'Honduras', 'HK': 'Hong Kong',
    'HU': 'Hungary', 'IS': 'Iceland', 'IN': 'India', 'ID': 'Indonesia', 'IR': 'Iran',
    'IQ': 'Iraq', 'IE': 'Ireland', 'IM': 'Isle of Man', 'IL': 'Israel', 'IT': 'Italy',
    'JM': 'Jamaica', 'JP': 'Japan', 'JE': 'Jersey', 'JO': 'Jordan', 'KZ': 'Kazakhstan',
    'KE': 'Kenya', 'KI': 'Kiribati', 'KP': "Korea, Democratic People's Republic of", 'KR': 'Korea, Republic of', 'KW': 'Kuwait',
    'KG': 'Kyrgyzstan', 'LA': "Lao People's Democratic Republic", 'LV': 'Latvia', 'LB': 'Lebanon', 'LS': 'Lesotho',
    'LR': 'Liberia', 'LY': 'Libya', 'LI': 'Liechtenstein', 'LT': 'Lithuania', 'LU': 'Luxembourg',
    'MO': 'Macao', 'MG': 'Madagascar', 'MW': 'Malawi', 'MY': 'Malaysia', 'MV': 'Maldives',
    'ML': 'Mali', 'MT': 'Malta', 'MH': 'Marshall Islands', 'MQ': 'Martinique', 'MR': 'Mauritania',
    'MU': 'Mauritius', 'YT': 'Mayotte', 'MX': 'Mexico', 'FM': 'Micronesia (Federated States of)', 'MD': 'Moldova',
    'MC': 'Monaco', 'MN': 'Mongolia', 'ME': 'Montenegro', 'MS': 'Montserrat', 'MA': 'Morocco',
    'MZ': 'Mozambique', 'MM': 'Myanmar', 'NA': 'Namibia', 'NR': 'Nauru', 'NP': 'Nepal',
    'NL': 'Netherlands', 'NC': 'New Caledonia', 'NZ': 'New Zealand', 'NI': 'Nicaragua', 'NE': 'Niger',
    'NG': 'Nigeria', 'NU': 'Niue', 'NF': 'Norfolk Island', 'MK': 'North Macedonia', 'MP': 'Northern Mariana Islands',
    'NO': 'Norway', 'OM': 'Oman', 'PK': 'Pakistan', 'PW': 'Palau', 'PS': 'Palestine, State of',
    'PA': 'Panama', 'PG': 'Papua New Guinea', 'PY': 'Paraguay', 'PE': 'Peru', 'PH': 'Philippines',
    'PN': 'Pitcairn', 'PL': 'Poland', 'PT': 'Portugal', 'PR': 'Puerto Rico', 'QA': 'Qatar',
    'RE': 'Réunion', 'RO': 'Romania', 'RU': 'Russian Federation', 'RW': 'Rwanda', 'BL': 'Saint Barthélemy',
    'SH': 'Saint Helena, Ascension and Tristan da Cunha', 'KN': 'Saint Kitts and Nevis', 'LC': 'Saint Lucia', 'MF': 'Saint Martin (French part)',
    'PM': 'Saint Pierre and Miquelon', 'VC': 'Saint Vincent and the Grenadines', 'WS': 'Samoa', 'SM': 'San Marino', 'ST': 'Sao Tome and Principe',
    'SA': 'Saudi Arabia', 'SN': 'Senegal', 'RS': 'Serbia', 'SC': 'Seychelles', 'SL': 'Sierra Leone',
    'SG': 'Singapore', 'SX': 'Sint Maarten (Dutch part)', 'SK': 'Slovakia', 'SI': 'Slovenia', 'SB': 'Solomon Islands',
    'SO': 'Somalia', 'ZA': 'South Africa', 'GS': 'South Georgia and the South Sandwich Islands', 'SS': 'South Sudan', 'ES': 'Spain',
    'LK': 'Sri Lanka', 'SD': 'Sudan', 'SR': 'Suriname', 'SJ': 'Svalbard and Jan Mayen', 'SE': 'Sweden',
    'CH': 'Switzerland', 'SY': 'Syrian Arab Republic', 'TW': 'Taiwan', 'TJ': 'Tajikistan', 'TZ': 'Tanzania, United Republic of',
    'TH': 'Thailand', 'TL': 'Timor-Leste', 'TG': 'Togo', 'TK': 'Tokelau', 'TO': 'Tonga',
    'TT': 'Trinidad and Tobago', 'TN': 'Tunisia', 'TR': 'Turkey', 'TM': 'Turkmenistan', 'TC': 'Turks and Caicos Islands',
    'TV': 'Tuvalu', 'UG': 'Uganda', 'UA': 'Ukraine', 'AE': 'United Arab Emirates', 'GB': 'United Kingdom',
    'US': 'United States of America', 'UM': 'United States Minor Outlying Islands', 'UY': 'Uruguay', 'UZ': 'Uzbekistan', 'VU': 'Vanuatu',
    'VE': 'Venezuela', 'VN': 'Viet Nam', 'VG': 'Virgin Islands (British)', 'VI': 'Virgin Islands (U.S.)', 'WF': 'Wallis and Futuna',
    'EH': 'Western Sahara', 'XK': 'Kosovo', 'YE': 'Yemen', 'ZM': 'Zambia', 'ZW': 'Zimbabwe'
}


// Utility function to validate URL format
const isValidUrl = (url: string): boolean => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' +
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' +
        '(\\#[-a-zA-Z\\d_]*)?$', 'i');
    return !!urlPattern.test(url);
};

const UrlInputGuest: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { showMessage } = useMessage();
    const [url, setUrl] = useState<string>('');
    const [conditions, setConditions] = useState<RedirectionConditionDTO[]>([]);
    const [newConditionType, setNewConditionType] = useState<ConditionType | ''>('');
    const [newConditionValue, setNewConditionValue] = useState<string>('');
    const [newRedirectUrl, setNewRedirectUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [useLandingPage, setUseLandingPage] = useState<boolean>(false); // State for landing page usage
    const [initialLandingPageContent, setInitialLandingPageContent] = useState<Section[]>([]); // State for landing page usage
    const [landingPageContent, setLandingPageContent] = useState<string>(''); // State for landing page content
    const { showConfirmation } = useConfirmationDialog();

    const handleAddCondition = () => {
        if (!newConditionType || !newConditionValue || !newRedirectUrl) {
            showMessage('error', t('Please fill in all fields for the condition.'));
            return;
        }

        if (!isValidUrl(newRedirectUrl)) {
            showMessage('error', t('Please enter a valid redirect URL.'));
            return;
        }

        if (conditions.some(cond => cond.condition_type === newConditionType && cond.condition_value === newConditionValue)) {
            showMessage('error', t('Duplicate condition type and value are not allowed.'));
            return;
        }

        setConditions([...conditions, {
            condition_type: newConditionType,
            condition_value: newConditionValue,
            redirect_url: newRedirectUrl,
        }]);

        setNewConditionType('');
        setNewConditionValue('');
        setNewRedirectUrl('');
    };

    const handleRemoveCondition = (index: number) => {
        setConditions(conditions.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!useLandingPage && !isValidUrl(url)) {
            showMessage('error', t('Please enter a valid destination URL.'));
            return;
        }

        if (!useLandingPage && conditions.some(condition => !isValidUrl(condition.redirect_url))) {
            showMessage('error', t('Please enter valid redirect URLs for all conditions.'));
            return;
        }

        if (useLandingPage && landingPageContent.length < 10) {
            showMessage('error', t('No content added for the landing page!'));
            return;
        }

        const redirect = await showConfirmation(t("Create an account to start building and customizing your own short URLs and landing pages. Confirm to continue to account creation!"), false, undefined, undefined, t("Information"));

        if (redirect) {
            window.location.href = "/#otp-login";
        }
    };

    return (
        <div className="url-input">
            <div>
                <div className="slug-options">
                    <label className={!useLandingPage ? 'active' : ''}>
                        <input
                            type="radio"
                            value="redirect"
                            disabled={id ? true : false}
                            checked={!useLandingPage}
                            onChange={() => setUseLandingPage(false)}
                        />
                        Redirect
                    </label>
                    <label className={useLandingPage ? 'active' : ''}>
                        <input
                            type="radio"
                            value="landingpage"
                            disabled={id ? true : false}
                            checked={useLandingPage}
                            onChange={() => setUseLandingPage(true)}
                        />
                        Show Landing Page
                    </label>
                </div>

                <div className="slug-content">
                    {!useLandingPage ? (
                        <div className="content">
                            <p>{t(`This URL will redirect visitors to the original link or to a specific destination if the conditions match.`)}</p>
                        </div>
                    ) : (
                        <div className="content">
                            <p>{t(`This link will display the custom landing page you built to your visitors.`)}</p>
                        </div>
                    )}
                </div>

                {!useLandingPage && (
                    <div className="redirection-fields">
                        <label htmlFor="original-url">{t(`Destination URL:`)}</label>
                        <input
                            type="text"
                            id="original-url"
                            placeholder="Enter the destination URL here..."
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                        <h3>{t(`Redirection Conditions`)}</h3>
                        <div className="condition-inputs">
                            <select value={newConditionType} onChange={(e) => setNewConditionType(e.target.value as ConditionType)}>
                                <option value="">{t(`Select Condition Type`)}</option>
                                {Object.keys(conditionOptions).map(type => (
                                    <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                                ))}
                            </select>
                            {newConditionType && (
                                <select value={newConditionValue} onChange={(e) => setNewConditionValue(e.target.value)}>
                                    <option value="">{t(`Select Value`)}</option>
                                    {(conditionOptions[newConditionType]).map(value => (
                                        <option key={value} value={value}>{newConditionType === 'country' ? countryCodes[value] : value}</option>
                                    ))}
                                </select>
                            )}
                            <input
                                type="text"
                                placeholder="Enter redirect URL"
                                value={newRedirectUrl}
                                onChange={(e) => setNewRedirectUrl(e.target.value)}
                            />
                            <button type="button" onClick={handleAddCondition}><FontAwesomeIcon icon={faAdd} />{t(`Add`)}</button>
                        </div>

                        <div className="conditions-list">
                            {conditions.map((condition, index) => (
                                <div key={index} className="condition-item">
                                    <span>If the <b>{condition.condition_type}</b> is <b>{condition.condition_value}</b> {"the visitor will be redirected to "} {condition.redirect_url}</span>
                                    <button type="button" className="remove-button" onClick={() => handleRemoveCondition(index)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <button type="button" disabled={isLoading} onClick={handleSubmit}>
                    {isLoading ? t('Processing...') : id ? t('Update Link ' + (useLandingPage ? '& Landing Page Changes' : 'Changes')) : t('Save Link ' + (useLandingPage ? '& Landing Page Changes' : 'Changes'))}
                </button>


                <div className="landing-page-option">
                    {useLandingPage && (
                        <LandingPageBuilder initialContent={initialLandingPageContent} onSave={(sections: Section[]) => {
                            setLandingPageContent(JSON.stringify(sections))
                        }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default UrlInputGuest;