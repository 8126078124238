import React, { ChangeEvent, useState } from 'react';
import { FeaturesSection } from '../../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ImageInputField from '../InputComponents/ImageInputField';
import { t } from 'i18next';

interface FeaturesSectionFormProps {
  initialContent: FeaturesSection['content'];
  onSave: (updatedContent: FeaturesSection['content']) => void;
}

const FeaturesSectionForm: React.FC<FeaturesSectionFormProps> = ({ initialContent, onSave }) => {
  const [content, setContent] = useState<FeaturesSection['content']>(initialContent);

  // Handle changes for background image and color
  const handleBasicChange = (e: ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  // Handle changes for individual features
  const handleFeatureChange = (index: number, e: ChangeEvent<HTMLInputElement>, changeParams?: { name: string, value: string }) => {
    let { name, value } = { name: '', value: '' };
    if (changeParams) {
      name = changeParams.name;
      value = changeParams.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const updatedFeatures = [...content.features];
    updatedFeatures[index] = {
      ...updatedFeatures[index],
      [name]: value
    };
    setContent((prevContent) => ({ ...prevContent, features: updatedFeatures }));
  };

  // Add a new feature
  const addFeature = () => {
    setContent((prevContent) => ({
      ...prevContent,
      features: [
        ...prevContent.features,
        { title: '', description: '', icon_url: '' } // Default new feature
      ]
    }));
  };

  // Remove a feature
  const removeFeature = (index: number) => {
    setContent((prevContent) => ({
      ...prevContent,
      features: prevContent.features.filter((_, i) => i !== index)
    }));
  };

  const handleSave = () => {
    onSave(content);
  };

  return (
    <div>
      <label>{t(`Colors:`)}</label>
      <div className="grid-div">
        <small>{t(`Background:`)}</small>
        <input
          type="color"
          name="background_color"
          value={content.background_color}
          onChange={handleBasicChange}
        />
        <small>{t(`Text:`)}</small>
        <input
          type="color"
          name="text_color"
          value={content.text_color}
          onChange={handleBasicChange}
        />
        <small>{t(`Items:`)}</small>
        <input
          type="color"
          name="items_color"
          value={content.items_color}
          onChange={handleBasicChange}
        />
      </div>
      <label>{t(`Title:`)}</label>
      <input
        type="text"
        name="title"
        value={content.title}
        onChange={handleBasicChange}
      />
      <label>{t(`Background Image URL:`)}</label>
      <ImageInputField
        name="background_image"
        value={content.background_image}
        onChange={handleBasicChange} />
      <div>
        <h3>{t(`Features`)}</h3>
        {content.features.map((feature, index) => (
          <div key={index} className="section-item">
            <label>{t(`Image URL:`)}</label>
            <ImageInputField
              name="icon_url"
              value={feature.icon_url}
              onChange={(e, changeParams) => handleFeatureChange(index, e, changeParams)} />
            <label>{t(`Title:`)}</label>
            <input
              type="text"
              name="title"
              value={feature.title}
              onChange={(e) => handleFeatureChange(index, e)}
            />
            <label>{t(`Description:`)}</label>
            <input
              type="text"
              name="description"
              value={feature.description}
              onChange={(e) => handleFeatureChange(index, e)}
            />
            <button className='delete-button' onClick={() => removeFeature(index)}><FontAwesomeIcon icon={faTrash} />{t(`Delete`)}</button>
          </div>
        ))}
        <button onClick={addFeature}><FontAwesomeIcon icon={faPlus} />{t(`Feature`)}</button>
      </div>

      <hr></hr>
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default FeaturesSectionForm;